import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { v4 as uuid } from 'uuid';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import { useInputLiquids } from 'client/app/apps/workflow-builder/lib/useElementContext';
import { calculateCombinatorialLayouts } from 'client/app/components/Parameters/PlateLayout/lib/combinatorialWellGenerationUtils';
import {
  reorderWellCoord,
  WellAvailability,
} from 'client/app/components/Parameters/PlateLayout/lib/WellAvailability';
import { MainPanelTab } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContents';
import {
  addWellsToWellSet,
  createPlateName,
  getLiquidParametersForElement,
  LiquidParameters,
  mapLiquidsToPlateLayer,
  removeWellsFromWellSet,
  useInputLiquidNamesAndGroups,
  useOutputMixturesByPlate,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import splitFullPlateName from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { Liquid, WellCoord } from 'common/types/bundle';
import {
  Measurement,
  VolumeOrConcentration,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import { Option } from 'common/types/Option';
import {
  LiquidAssignment,
  LiquidSortMode,
  NamingMode,
  PlateAssignment,
  PlateAssignmentMode,
  PlateLayer,
  SortOrder,
  WellSet,
  WellSortMode,
} from 'common/types/plateAssignments';
import { PlateType } from 'common/types/plateType';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

type SidePanelTab = 'setup' | 'liquids';

type State = {
  plateAssignment: PlateAssignment;
  isReadonly: boolean;
  plateType?: PlateType;
  focusedLayerId?: string;
  liquidColors: LiquidColors;
  selectedWells: readonly WellLocationOnDeckItem[];
  editingAvailability: boolean;
  wellAvailability: WellAvailability | undefined;
  setAvailableWells: (value: readonly WellLocationOnDeckItem[]) => void;
  setFocusedLayerId: (value: string) => void;
  toggleEditingAvailability: (value: boolean) => void;
  highlightedLiquidId: string | undefined;
  setPlateType: (value?: PlateParameterValue, resetPlateAssignment?: boolean) => void;
  setReplicates: (value: number) => void;
  setTotalVolume: (value?: Measurement) => void;
  setDiluentName: (value: string) => void;
  setPlateAssignmentMode: (
    value: PlateAssignmentMode,
    resetPlateAssignment?: boolean,
  ) => void;
  selectedLiquidOrLayerId: string | undefined;
  setSelectedLiquidOrLayerId: (id: string | undefined) => void;
  addNewLayer: () => void;
  setPlateLayerName: (id: string, newName: string) => void;
  setSelectedWells: (value: readonly WellLocationOnDeckItem[]) => void;
  addSelectedWellsToSelectedLiquid: () => void;
  removeSelectedWellsFromSelectedLiquid: () => void;
  clearSelectedWells: () => void;
  setLiquidIdentifier: (id: string, newName: string, isPartOfGroup: boolean) => void;
  setLiquidVolumeOrConcentration: (
    id: string,
    volumeOrConcentration: VolumeOrConcentration,
  ) => void;
  activeSidePanelTab: SidePanelTab;
  setActiveSidePanelTab: (tab: SidePanelTab) => void;
  activeMainTab: MainPanelTab;
  setActiveMainTab: (tab: MainPanelTab) => void;
  setWellSetSortMode: (id: string, sortBy: WellSortMode) => void;
  setLayerSortMode: (sortBy: WellSortMode) => void;
  setNamingPlateLayerId: (id: string) => void;
  setNamingMode: (namingMode: NamingMode) => void;
  setLiquidSortOrder: (
    wellSetId: string,
    sortBy: LiquidSortMode,
    sortOrder: SortOrder,
  ) => void;
  setLiquidSortSubComponent: (
    wellSetId: string,
    subComponentName: string | undefined,
  ) => void;
  addNewLiquidAssignment: () => void;
  deleteLayer: (layerId: string) => void;
  reorderLayers: (reorderedLayers: PlateLayer[]) => void;
  reorderLiquidAssignments: (
    layerID: string,
    reorderedLiquidAssignments: LiquidAssignment[],
  ) => void;
  deleteLiquidAssignment: (liquidAssigmentId: string) => void;
  setHighlightedLiquidId: Dispatch<SetStateAction<string | undefined>>;
  combinatorialPlateLayouts: Map<string, PlateAssignment> | undefined;
  selectedPlateName: string | undefined;
  setSelectedPlateName: (name: string) => void;
  inputLiquids: Liquid[];
  isMixOnto: boolean;
  liquidParameters: LiquidParameters;
  plateLayers: PlateLayer[];
  plateOptions: Option<string>[] | undefined;
  notifyDeletedLiquids: string[] | undefined;
  clearNotifyDeletedLiquids: () => void;
};

const PlateLayoutEditorContext = createContext<State>({} as any);

// This layer has a special ID to allow elements to identify it, because the destination layer
// is not serialised into the workflow JSON.
const DESTINATION_LAYER_ID = 'destination-layer';

export default function PlateLayoutEditorContextProvider({
  children,
}: PropsWithChildren) {
  // we limit workflow builder state only to the props passed from
  // `ParameterEditor` > `PlateLayoutParameter`. This makes the panel more
  // re-usable e.g. in protocols
  const { plateNames, plateAssignment, updatePlateAssignment, isReadonly, isMixOnto } =
    usePlateLayoutParameterProps();

  const [plateTypes] = usePlatesByType();
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);
  const [selectedWells, setSelectedWells] = useState<readonly WellLocationOnDeckItem[]>(
    [],
  );
  const [editingAvailability, setEditingAvailability] = useState(false);
  const [wellAvailability, setWellAvailability] = useState<
    WellAvailability | undefined
  >();
  const [highlightedLiquidId, setHighlightedLiquidId] = useState<string | undefined>();
  const [notifyDeletedLiquids, setNotifyDeletedLiquids] = useState<
    string[] | undefined
  >();

  const clearNotifyDeletedLiquids = useCallback(() => {
    setNotifyDeletedLiquids(undefined);
  }, []);

  const liquidParameters = getLiquidParametersForElement(isMixOnto);

  const liquidParamsToLoad = useMemo(
    () =>
      liquidParameters.existingLiquids
        ? [liquidParameters.inputLiquids, liquidParameters.existingLiquids]
        : liquidParameters.inputLiquids,
    [liquidParameters.existingLiquids, liquidParameters.inputLiquids],
  );

  const {
    inputLiquids: [inputLiquids, existingLiquids],
  } = useInputLiquids(liquidParamsToLoad);
  const liquidNamesAndGroups = useInputLiquidNamesAndGroups(inputLiquids);
  const [_loading, outputPlates, _outputMixtures] = useOutputMixturesByPlate(
    liquidColors,
    liquidParameters,
  );

  const defaultPlateName = createPlateName(plateNames[0], 0, isMixOnto);
  const [selectedPlateName, setSelectedPlateName] = useState<string>(defaultPlateName);

  // If the user removes the selected plate from the instance panel assigment,
  // we need to reset to a different plate (if there is one, if not the panel
  // will be auto-closed by a different effect).
  useEffect(() => {
    if (
      plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE &&
      !plateNames.includes(selectedPlateName) &&
      ![...(outputPlates.keys() ?? [])].includes(selectedPlateName)
    ) {
      if (plateNames.length > 0) {
        setSelectedPlateName(defaultPlateName);
      }
    }
  }, [
    defaultPlateName,
    outputPlates,
    plateAssignment.assignmentMode,
    plateNames,
    selectedPlateName,
  ]);

  const existingLiquidsLayer = useMemo<PlateLayer | undefined>(() => {
    if (!existingLiquids) {
      return undefined;
    }

    return mapLiquidsToPlateLayer(
      existingLiquids,
      'Destination Liquids',
      selectedPlateName,
      DESTINATION_LAYER_ID,
    );
  }, [existingLiquids, selectedPlateName]);

  useEffect(() => {
    if (
      plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL &&
      plateAssignment.plateType &&
      plateTypes(plateAssignment.plateType)
    ) {
      if (!wellAvailability) {
        const plate = plateTypes(plateAssignment.plateType);
        const currentAvailableWells =
          plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
            ?.wells;
        const sortMode =
          plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
            ?.sortBy ?? WellSortMode.BY_ROW;
        const newWellAvailability = currentAvailableWells
          ? new WellAvailability(
              plateTypes(plateAssignment.plateType),
              sortMode,
            ).withAvailability(
              currentAvailableWells.map(well => ({
                col: well.x,
                row: well.y,
                deck_item_id: plate.id,
              })),
            )
          : new WellAvailability(plateTypes(plateAssignment.plateType), sortMode);
        setWellAvailability(newWellAvailability);
      }
    }
  }, [
    plateAssignment.assignmentMode,
    plateAssignment.plateLayers,
    plateAssignment.plateType,
    plateTypes,
    wellAvailability,
  ]);

  const combinatorialPlateLayouts = useMemo(() => {
    if (
      !plateAssignment.plateType ||
      !plateTypes(plateAssignment.plateType) ||
      !liquidNamesAndGroups ||
      plateAssignment.assignmentMode !== PlateAssignmentMode.COMBINATORIAL ||
      !plateNames[0]
    ) {
      return undefined;
    }
    return calculateCombinatorialLayouts(
      plateAssignment,
      plateTypes(plateAssignment.plateType),
      liquidNamesAndGroups,
      plateNames[0],
      wellAvailability,
    );
  }, [plateAssignment, plateTypes, liquidNamesAndGroups, plateNames, wellAvailability]);

  useEffect(() => {
    if (
      plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL &&
      ((combinatorialPlateLayouts && !selectedPlateName) ||
        (selectedPlateName && !combinatorialPlateLayouts?.has(selectedPlateName)))
    ) {
      const newName = combinatorialPlateLayouts?.keys().next().value;
      if (newName) {
        setSelectedPlateName(newName);
      }
    }
  }, [selectedPlateName, combinatorialPlateLayouts, plateAssignment.assignmentMode]);

  const [focusedLayerId, setFocusedLayerId] = useState(
    plateAssignment.plateLayers[0]?.id,
  );

  const setPlateType = useCallback(
    (value?: PlateParameterValue, resetPlateAssignment?: boolean) => {
      const plateTypeNameWithRiser = value && typeof value === 'string' ? value : '';
      const plateType = plateTypeNameWithRiser
        ? plateTypes(plateTypeNameWithRiser)
        : undefined;

      const defaultValues = defaultPlateAssignment(isMixOnto);

      updatePlateAssignment(draft => {
        if (resetPlateAssignment) {
          draft.diluentName = defaultValues.diluentName;
          draft.plateLayers = defaultValues.plateLayers;
          draft.replicates = defaultValues.replicates;
          draft.totalVolume = defaultValues.totalVolume;
          draft.namingMode = defaultValues.namingMode;
          draft.namingPlateLayerID = defaultValues.namingPlateLayerID;
        }
        draft.plateType = plateTypeNameWithRiser;
      });

      if (plateType && wellAvailability) {
        setWellAvailability(wellAvailability.withPlate(plateType));
      }
    },
    [isMixOnto, plateTypes, updatePlateAssignment, wellAvailability],
  );

  const setPlateAssignmentMode = useCallback(
    (value: PlateAssignmentMode, resetPlateAssignment?: boolean) => {
      const defaultValues = defaultPlateAssignment(isMixOnto);
      updatePlateAssignment(draft => {
        if (resetPlateAssignment) {
          draft.diluentName = defaultValues.diluentName;
          draft.plateLayers = defaultValues.plateLayers;
          draft.replicates = defaultValues.replicates;
          draft.totalVolume = defaultValues.totalVolume;
          draft.namingMode = defaultValues.namingMode;
          draft.namingPlateLayerID = defaultValues.namingPlateLayerID;
        }
        draft.assignmentMode = value;
      });
      setSelectedWells([]);
      setEditingAvailability(false);
    },
    [isMixOnto, updatePlateAssignment],
  );

  const updatePlateLayer = useCallback(
    (id: string, value: Partial<Omit<PlateLayer, 'id'>>) => {
      updatePlateAssignment(draft => {
        const existingLayerIndex = draft.plateLayers.findIndex(layer => layer.id === id);
        draft.plateLayers[existingLayerIndex] = {
          ...draft.plateLayers[existingLayerIndex],
          ...value,
        };
      });
    },
    [updatePlateAssignment],
  );

  const setPlateLayerName = useCallback(
    (id: string, newName: string) => {
      updatePlateLayer(id, { name: newName });
    },
    [updatePlateLayer],
  );

  const addNewLayer = useCallback(() => {
    const newLayerID = uuid();
    updatePlateAssignment(draft => {
      draft.plateLayers.unshift({
        id: newLayerID,
        name: '',
        liquids: [],
        wellSets: [],
        isReadOnly: false,
      });
    });
    setFocusedLayerId(newLayerID);
  }, [updatePlateAssignment]);

  const updateLiquidAssignment = useCallback(
    (id: string, value: Partial<Omit<LiquidAssignment, 'id'>>) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          const liquidIndex = layer.liquids.findIndex(liquid => liquid.wellSetID === id);
          if (liquidIndex > -1) {
            draft.plateLayers[layerIndex].liquids[liquidIndex] = {
              ...draft.plateLayers[layerIndex].liquids[liquidIndex],
              ...value,
            };
          }
        });
      });
    },
    [updatePlateAssignment],
  );

  const updateWellSet = useCallback(
    (id: string, value: Partial<Omit<WellSet, 'id'>>) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          const wellSetIndex = layer.wellSets.findIndex(wellSet => wellSet.id === id);
          if (wellSetIndex > -1) {
            draft.plateLayers[layerIndex].wellSets[wellSetIndex] = {
              ...draft.plateLayers[layerIndex].wellSets[wellSetIndex],
              ...value,
            };
          }
        });
      });
    },
    [updatePlateAssignment],
  );

  const setWellSetSortMode = useCallback(
    (id: string, sortMode: WellSortMode | undefined) => {
      updateWellSet(id, {
        sortBy: sortMode,
      });
    },
    [updateWellSet],
  );

  const setNamingMode = useCallback(
    (namingMode: NamingMode | null) => {
      namingMode !== null && // don't allow complete deselection of the mode
        updatePlateAssignment(draft => {
          draft.namingMode = namingMode;
        });
    },
    [updatePlateAssignment],
  );

  const setNamingPlateLayerId = useCallback(
    (id: string) => {
      updatePlateAssignment(draft => {
        draft.namingPlateLayerID = id;
      });
    },
    [updatePlateAssignment],
  );

  const setLayerSortMode = useCallback(
    (sortMode: WellSortMode | undefined) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach(layer => {
          layer.wellSets.forEach(wellSet => {
            wellSet.sortBy = sortMode;
            wellSet.wells = reorderWellCoord(wellSet.wells, sortMode);
          });
        });
        if (sortMode) {
          wellAvailability?.setSortMode(sortMode);
        }
      });
    },
    [updatePlateAssignment, wellAvailability],
  );
  const setLiquidSortOrder = useCallback(
    (wellSetId: string, sortBy: LiquidSortMode, sortOrder: SortOrder) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          layer.liquids.forEach((liquid, liquidIndex) => {
            if (liquid.wellSetID === wellSetId) {
              draft.plateLayers[layerIndex].liquids[liquidIndex] = {
                ...draft.plateLayers[layerIndex].liquids[liquidIndex],
                sortOrder: sortOrder,
                sortBy: sortBy,
              };
            }
          });
        });
      });
    },
    [updatePlateAssignment],
  );

  const setLiquidSortSubComponent = useCallback(
    (wellSetId: string, subComponentName: string | undefined) => {
      updatePlateAssignment(draft => {
        for (const layer of draft.plateLayers) {
          for (const liquid of layer.liquids) {
            if (liquid.wellSetID === wellSetId) {
              liquid.subComponentName = subComponentName;
              return;
            }
          }
        }
      });
    },
    [updatePlateAssignment],
  );

  const setLiquidIdentifier = useCallback(
    (id: string, newName: string, isPartOfGroup: boolean) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          // If user has updated the name to be a non-group, we should remove any
          // group-specific parameters from the well set, which is achieved here
          // both in the liquid (removing sort options) and wellset (removing sort
          // options).

          const liquidIndex = layer.liquids.findIndex(liquid => liquid.wellSetID === id);
          if (liquidIndex > -1) {
            draft.plateLayers[layerIndex].liquids[liquidIndex] = {
              ...draft.plateLayers[layerIndex].liquids[liquidIndex],
              ...(isPartOfGroup
                ? {
                    liquidGroup: newName,
                    liquidName: undefined,
                    sortBy:
                      draft.plateLayers[layerIndex].liquids[liquidIndex].sortBy ??
                      LiquidSortMode.NONE,
                    sortOrder:
                      draft.plateLayers[layerIndex].liquids[liquidIndex].sortOrder ??
                      SortOrder.NONE,
                  }
                : {
                    liquidName: newName,
                    liquidGroup: undefined,
                    sortBy: undefined,
                    sortOrder: undefined,
                  }),
            };
          }
          const wellSetIndex = layer.wellSets.findIndex(wellSet => wellSet.id === id);
          if (wellSetIndex > -1) {
            draft.plateLayers[layerIndex].wellSets[wellSetIndex] = {
              ...draft.plateLayers[layerIndex].wellSets[wellSetIndex],
              ...(!isPartOfGroup
                ? {
                    sortBy:
                      draft.plateLayers[layerIndex].wellSets[wellSetIndex].sortBy ??
                      undefined,
                  }
                : {
                    sortBy:
                      draft.plateLayers[layerIndex].wellSets[wellSetIndex].sortBy ??
                      WellSortMode.BY_ROW,
                  }),
            };
          }
        });
      });
    },
    [updatePlateAssignment],
  );

  const setLiquidVolumeOrConcentration = useCallback(
    (id: string, volumeOrConcentration: VolumeOrConcentration) => {
      updateLiquidAssignment(id, {
        target: volumeOrConcentration,
      });
    },
    [updateLiquidAssignment],
  );

  const [selectedLiquidOrLayerId, setSelectedLiquidOrLayerId] = useState<
    string | undefined
  >(undefined);

  const [activeSidePanelTab, setActiveSidePanelTab] = useState<SidePanelTab>(
    plateAssignment.plateType ? 'liquids' : 'setup',
  );

  const [activeMainTab, setActiveMainTab] = useState<MainPanelTab>(MainPanelTab.LAYOUT);

  const plateOptions = useMemo(() => {
    const createOptions = (names: string[]) => {
      return names.map(name => ({ label: name, value: name }));
    };
    if (plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL) {
      return createOptions([...(combinatorialPlateLayouts?.keys() ?? [])]);
    }
    return createOptions([...(outputPlates.keys() ?? [])]);
  }, [combinatorialPlateLayouts, outputPlates, plateAssignment.assignmentMode]);

  const addNewLiquidAssignment = useCallback(() => {
    if (!focusedLayerId) {
      return;
    }
    const layer = plateAssignment.plateLayers.find(layer => layer.id === focusedLayerId);
    if (!layer) {
      return;
    }
    const sortBy =
      plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL
        ? layer.wellSets[0]?.sortBy ?? WellSortMode.BY_ROW
        : undefined;
    const newLiquidWellSetID = uuid();

    const wellsFromAvailability: WellCoord[] =
      wellAvailability?.available.map(well => ({
        x: well.col,
        y: well.row,
      })) ?? [];

    updatePlateAssignment(draft => {
      const layer = draft.plateLayers.find(layer => layer.id === focusedLayerId);

      if (layer) {
        layer.liquids.push({
          wellSetID: newLiquidWellSetID,
          liquidName: 'New Liquid',
          target: { concentration: { value: 1, unit: 'X' } },
        });

        layer.wellSets.push({
          id: newLiquidWellSetID,
          wells:
            plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL
              ? wellsFromAvailability
              : [],
          sortBy: sortBy,
        });

        addWellsToWellSet(layer, newLiquidWellSetID, selectedWells);
      }
    });

    setSelectedWells([]);
    setSelectedLiquidOrLayerId(newLiquidWellSetID);
  }, [
    focusedLayerId,
    plateAssignment.assignmentMode,
    plateAssignment.plateLayers,
    selectedWells,
    updatePlateAssignment,
    wellAvailability?.available,
  ]);

  const addSelectedWellsToSelectedLiquid = useCallback(() => {
    updatePlateAssignment(assignment => {
      if (selectedLiquidOrLayerId) {
        const layer = assignment.plateLayers.find(layer =>
          layer.wellSets.some(ws => ws.id === selectedLiquidOrLayerId),
        );

        if (layer) {
          addWellsToWellSet(layer, selectedLiquidOrLayerId, selectedWells);
        }
      }
    });

    setSelectedWells([]);
  }, [selectedLiquidOrLayerId, selectedWells, updatePlateAssignment]);

  const removeSelectedWellsFromSelectedLiquid = useCallback(() => {
    updatePlateAssignment(assignment => {
      const selectedWellSet = assignment.plateLayers
        .flatMap(layer => layer.wellSets)
        .find(wellSet => wellSet.id === selectedLiquidOrLayerId);

      if (selectedWellSet) {
        removeWellsFromWellSet(selectedWellSet, selectedWells);
      }

      setSelectedWells([]);
    });
  }, [selectedLiquidOrLayerId, selectedWells, updatePlateAssignment]);

  const clearSelectedWells = useCallback(() => {
    updatePlateAssignment(assignment => {
      const deletedLiquids: LiquidAssignment[] = [];

      for (const layer of assignment.plateLayers) {
        const toDelete: string[] = [];

        layer.wellSets.forEach(wellSet => {
          for (const well of selectedWells) {
            const isInLiquid = wellSet.wells.some(
              value => value.x === well.col && value.y === well.row,
            );
            if (isInLiquid) {
              removeWellsFromWellSet(wellSet, selectedWells);

              if (wellSet.wells.length === 0) {
                toDelete.push(wellSet.id);
              }
            }
          }
        });

        toDelete.forEach(id => {
          deletedLiquids.push(...deleteLiquidFromLayer(layer, id));
        });
      }

      setNotifyDeletedLiquids(
        deletedLiquids.length > 0
          ? deletedLiquids.map(la => la.liquidName ?? '')
          : undefined,
      );

      setSelectedWells([]);
    });
  }, [selectedWells, updatePlateAssignment]);

  const deleteLayer = useCallback(
    (layerId: string) => {
      updatePlateAssignment(assignment => {
        assignment.plateLayers = assignment.plateLayers.filter(
          layer => layer.id !== layerId,
        );
        if (assignment.namingPlateLayerID === layerId) {
          // Reset this to the bottom layer
          const firstLayerId = isMixOnto
            ? DESTINATION_LAYER_ID
            : assignment.plateLayers[assignment.plateLayers.length - 1].id;
          assignment.namingPlateLayerID = firstLayerId;
        }
      });

      if (focusedLayerId === layerId) {
        // If we delete this layer, and the user had an item selected
        // then we should pick the nearest layer and select that, to prevent
        // the user having to reselect layers. We will pick the nearest layer below
        // or above, if they are available.
        const layerIndex = plateAssignment.plateLayers.findIndex(
          layer => layer.id === layerId,
        );
        const nearestLayerId =
          plateAssignment.plateLayers[layerIndex - 1]?.id ??
          plateAssignment.plateLayers[layerIndex + 1]?.id ??
          undefined;
        setFocusedLayerId(nearestLayerId);
        setSelectedLiquidOrLayerId(undefined);
      }
    },
    [focusedLayerId, isMixOnto, plateAssignment.plateLayers, updatePlateAssignment],
  );

  const reorderLayers = useCallback(
    (reorderedLayers: PlateLayer[]) => {
      updatePlateAssignment(assignment => {
        assignment.plateLayers = reorderedLayers.filter(layer => !layer.isReadOnly);
      });
    },
    [updatePlateAssignment],
  );

  const reorderLiquidAssignments = useCallback(
    (layerID: string, reorderedLiquidAssignments: LiquidAssignment[]) => {
      updatePlateAssignment(assignment => {
        const plateLayer = assignment.plateLayers.find(layer => layer.id === layerID);
        if (plateLayer) {
          plateLayer.liquids = reorderedLiquidAssignments;
        }
      });
    },
    [updatePlateAssignment],
  );

  const deleteLiquidAssignment = useCallback(
    (liquidAssigmentId: string) => {
      updatePlateAssignment(assignment => {
        assignment.plateLayers.forEach(layer => {
          deleteLiquidFromLayer(layer, liquidAssigmentId);
        });
      });

      if (selectedLiquidOrLayerId === liquidAssigmentId) {
        setSelectedLiquidOrLayerId(undefined);
      }
    },
    [selectedLiquidOrLayerId, updatePlateAssignment],
  );

  const setAvailableWells = useCallback((value: readonly WellLocationOnDeckItem[]) => {
    setWellAvailability(current => current?.withAvailability(value));
  }, []);

  const toggleEditingAvailability = useCallback(
    (value: boolean) => {
      if (value && !wellAvailability && plateAssignment.plateType) {
        const plateType = plateTypes(plateAssignment.plateType);
        const sortMode =
          plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
            ?.sortBy ?? WellSortMode.BY_ROW;
        setWellAvailability(new WellAvailability(plateType, sortMode));
      }
      setEditingAvailability(value);

      if (!value && wellAvailability) {
        const wells = wellAvailability.available.map<WellCoord>(({ col, row }) => ({
          x: col,
          y: row,
        }));

        updatePlateAssignment(draft => {
          draft.plateLayers.forEach(layer => {
            layer.wellSets.forEach(ws => {
              ws.wells = wells;
            });
          });
        });
      }
    },
    [
      wellAvailability,
      plateAssignment.plateType,
      plateAssignment.plateLayers,
      plateTypes,
      updatePlateAssignment,
    ],
  );

  const setReplicates = useCallback(
    (value: number) => {
      updatePlateAssignment(draft => {
        draft.replicates = value;
      });
    },
    [updatePlateAssignment],
  );

  const setTotalVolume = useCallback(
    (value?: Measurement) => {
      updatePlateAssignment(draft => {
        draft.totalVolume = value;
      });
    },
    [updatePlateAssignment],
  );

  const setDiluentName = useCallback(
    (value?: string) => {
      updatePlateAssignment(draft => {
        draft.diluentName = value;
      });
    },
    [updatePlateAssignment],
  );

  const plateTypeName = plateAssignment.plateType
    ? splitFullPlateName(plateAssignment.plateType)[0]
    : undefined;
  const plateType = plateTypeName ? plateTypes(plateTypeName) : undefined;

  const value = useMemo<State>(
    () => ({
      focusedLayerId,
      wellAvailability,
      editingAvailability,
      isReadonly,
      liquidColors,
      plateAssignment,
      plateType,
      selectedWells: selectedWells,
      highlightedLiquidId,
      setHighlightedLiquidId,
      setPlateType,
      setPlateAssignmentMode,
      selectedLiquidOrLayerId,
      setSelectedLiquidOrLayerId,
      addNewLayer,
      setPlateLayerName,
      setSelectedWells,
      setLiquidIdentifier,
      setLiquidVolumeOrConcentration,
      activeSidePanelTab,
      setActiveSidePanelTab,
      activeMainTab,
      setActiveMainTab,
      addNewLiquidAssignment,
      addSelectedWellsToSelectedLiquid,
      removeSelectedWellsFromSelectedLiquid,
      clearSelectedWells,
      deleteLayer,
      reorderLayers,
      reorderLiquidAssignments,
      deleteLiquidAssignment,
      setAvailableWells,
      toggleEditingAvailability,
      setWellSetSortMode,
      setLayerSortMode,
      setNamingMode,
      setNamingPlateLayerId,
      setLiquidSortOrder,
      setFocusedLayerId: setFocusedLayerId,
      setReplicates,
      setTotalVolume,
      setDiluentName,
      combinatorialPlateLayouts,
      selectedPlateName,
      setSelectedPlateName,
      setLiquidSortSubComponent,
      inputLiquids,
      isMixOnto,
      liquidParameters,
      plateLayers: existingLiquidsLayer
        ? [...plateAssignment.plateLayers, existingLiquidsLayer]
        : plateAssignment.plateLayers,
      plateOptions,
      notifyDeletedLiquids,
      clearNotifyDeletedLiquids,
    }),
    [
      focusedLayerId,
      wellAvailability,
      editingAvailability,
      isReadonly,
      liquidColors,
      plateAssignment,
      plateType,
      selectedWells,
      highlightedLiquidId,
      setPlateType,
      setPlateAssignmentMode,
      selectedLiquidOrLayerId,
      addNewLayer,
      setPlateLayerName,
      setLiquidIdentifier,
      setLiquidVolumeOrConcentration,
      activeSidePanelTab,
      activeMainTab,
      addNewLiquidAssignment,
      addSelectedWellsToSelectedLiquid,
      removeSelectedWellsFromSelectedLiquid,
      clearSelectedWells,
      deleteLayer,
      reorderLayers,
      reorderLiquidAssignments,
      deleteLiquidAssignment,
      setAvailableWells,
      toggleEditingAvailability,
      setWellSetSortMode,
      setLayerSortMode,
      setNamingMode,
      setNamingPlateLayerId,
      setLiquidSortOrder,
      setReplicates,
      setTotalVolume,
      setDiluentName,
      combinatorialPlateLayouts,
      selectedPlateName,
      setLiquidSortSubComponent,
      inputLiquids,
      isMixOnto,
      liquidParameters,
      existingLiquidsLayer,
      plateOptions,
      notifyDeletedLiquids,
      clearNotifyDeletedLiquids,
    ],
  );

  return (
    <PlateLayoutEditorContext.Provider value={value}>
      {children}
    </PlateLayoutEditorContext.Provider>
  );
}

export function usePlateLayoutEditorContext() {
  return useContext(PlateLayoutEditorContext);
}

export function defaultPlateAssignment(isMixOnto?: boolean): PlateAssignment {
  const firstLayerID = uuid();
  return {
    assignmentMode: PlateAssignmentMode.DESCRIPTIVE,
    replicates: 1,
    plateType: '',
    plateLayers: [
      {
        id: firstLayerID,
        liquids: [],
        name: '',
        wellSets: [],
        isReadOnly: false,
      },
    ],
    namingMode: NamingMode.NONE,
    namingPlateLayerID: isMixOnto ? DESTINATION_LAYER_ID : firstLayerID,
  };
}

/**
 * Deletes the liquids with the given wellSetId from the layer, and removes the
 * associated wellSet object. If the wellSetId is not found, does nothing.
 *
 * @param layer Layer from which to delete.
 * @param wellSetID ID of wellset to delete.
 * @returns The liquids that were deleted, otherwise an empty array.
 */
export function deleteLiquidFromLayer(
  layer: WritableDraft<PlateLayer>,
  wellSetID: string,
) {
  const removedIndex = layer.liquids.findIndex(liquid => liquid.wellSetID === wellSetID);
  if (removedIndex < 0) {
    return [];
  }
  layer.wellSets = layer.wellSets.filter(wellSet => wellSet.id !== wellSetID);
  return layer.liquids.splice(removedIndex, 1);
}

export function usePlateLayoutParameterProps() {
  // these props are only provided with the initial values on opening the
  // panel. We are in charge of maintaining the state internally
  const props = useWorkflowBuilderSelector(state => state.plateLayoutEditor);
  if (!props) {
    throw new Error('Plate layout editor was opened without valid workflow state');
  }

  const { value, onChange, isDisabled, hasLiquidsInPlate } = props;
  const plateNames = value.plateNames;
  const plateAssignmentId = value.id;

  // The elements (i.e. original value) expect the layers to be passed in order
  // if dispensing. In the UI (local state), we show them layers in reverse.
  const [plateAssignment, setPlateAssignment] = useState(() =>
    produce(value.plateAssignment, draft => {
      draft.plateLayers.reverse();
    }),
  );

  const updatePlateAssignment = useCallback(
    (update: (draft: WritableDraft<PlateAssignment>) => void) => {
      setPlateAssignment(prev => {
        const next = produce(prev, update);

        // serialise values are in un-reversed layer order
        onChange({
          id: plateAssignmentId,
          plateNames: plateNames,
          plateAssignment: produce(next, draft => {
            draft.plateLayers.reverse();
          }),
        });

        // local state remains in reverse layers
        return next;
      });
    },
    [onChange, plateAssignmentId, plateNames],
  );

  return {
    plateNames,
    plateAssignment,
    updatePlateAssignment,
    isReadonly: isDisabled,
    isMixOnto: !hasLiquidsInPlate,
  };
}
